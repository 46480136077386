import {FC, useCallback, useRef} from 'react';
import styled, {useTheme} from 'styled-components';

import {textColor} from '@shared/frontends/frontend_theme_utils';
import {isNonEmptyArray, nonEmptyArrayOrThrow} from '@shared/lib/type_utils';

import {NavButton, NavLink} from '@shared-frontend/components/core/button';
import {EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

interface AdminBarProps {
  mode?: 'bar' | 'button';
  adminPath?: string;
}

export const AdminBar: FC<AdminBarProps> = ({mode = 'bar', adminPath = ''}) => {
  const theme = useTheme();
  const session = useSession();
  const {isMobile} = useResponsive();

  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);
  const handleWrapperClick = useCallback(() => {
    if (!wrapperRef.current) {
      return;
    }
    const nextChild = wrapperRef.current.nextSibling;
    if (!nextChild) {
      return;
    }
    if (nextChild instanceof HTMLElement) {
      nextChild.scrollIntoView({block: 'start'});
    }
  }, []);

  if (!('sessionId' in session)) {
    return EmptyFragment;
  }

  const isAdmin = isNonEmptyArray(session.adminGroups) || session.isAdmin;
  if (!isAdmin) {
    return EmptyFragment;
  }

  const pathPrefix = session.isAdmin
    ? '/admin'
    : `/cse-admin/${nonEmptyArrayOrThrow(session.adminGroups)[0].groupId}`;
  const path = `${pathPrefix}${adminPath}`;
  if (mode === 'bar') {
    return (
      <WrapperBar ref={wrapperRef} onClick={handleWrapperClick} $isMobile={isMobile}>
        <span>{`Administrateur ${session.email}`}</span>
        <NavLink to={path} overrides={{...textColor(theme.main.accentTextColor)}}>
          Aller à l'admin
        </NavLink>
      </WrapperBar>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (mode === 'button') {
    return (
      <WrapperButton>
        <NavButton to={path} overrides={{...textColor(theme.main.accentTextColor)}}>
          Aller à l'admin
        </NavButton>
      </WrapperButton>
    );
  }
  return EmptyFragment;
};
AdminBar.displayName = 'AdminBar';

const WrapperBar = styled.div<{$isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px; // Fixed to 32px, do not change
  padding: 0 16px;
  font-size: ${({$isMobile}) => ($isMobile ? '10px' : '14px')};
  background-color: ${p => p.theme.main.accentColor};
  color: ${p => p.theme.main.accentTextColor};
`;

const WrapperButton = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
`;
